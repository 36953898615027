import React, { useMemo, useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Grid, Box, H3, Btn } from '@saladbob/sassafras';
import StickyBox from "react-sticky-box";
import { X } from 'react-feather';

import Carousel from "../media/Carousel";
import NoticeCard from "./NoticeCard";
import { Notice } from "../../types";

type Props = {
    notices: Notice[],
    page?: string,
};

const style: React.CSSProperties = {
    zIndex: 100,
    overflow: 'hidden',
}

const NoticeBar: React.FC<Props> = ({ notices, page }) => {
    const [hovering, setHovering] = useState(false);
    const [hide, setHide] = useState(true);
    const firstLoad = useRef(true);
    const filteredNotices = useMemo<Notice[]>(() => {
        if (!notices || notices.length === 0) return [];
        return notices.filter(notice => new Date(notice.expiration) > new Date());
    }, [notices]);

    useEffect(() => {
        if (firstLoad.current && window.sessionStorage.getItem(`hideNoticeBar${page}`) !== 'true') {
            setHide(false);
        } else if (!firstLoad.current) {
            if (hide) {
                window.sessionStorage.setItem(`hideNoticeBar${page}`, 'true');
            }
        }
        firstLoad.current = false;
    }, [hide]);

    if (!notices || filteredNotices.length === 0) return null;

    return  (
        <StickyBox style={style}>
            <AnimatePresence initial={false}>
                {!hide && (
                    <motion.div
                        exit={{ height: 0 }}
                        transition={{ duration: 0.3 }}
                        onMouseEnter={() => setHovering(true)}
                        onMouseLeave={() => setHovering(false)}
                    >
                        <Grid columns="min-content 1fr" overflow="hidden">
                            <Box
                                txtColor="white"
                                verticalAlign="center"
                                bgColor="tertiary"
                                ps="sm"
                                pe="md"
                            >
                                <Btn size="xs" outline color="black" onClick={() => { setHide(true) }}><X size={16} /></Btn>
                            </Box>
                            <Carousel gap={0} duration={5000} autoplay buttons buttonsColor="primary" columns={1}>
                                {filteredNotices.map(notice => (
                                    <NoticeCard key={notice.id} notice={notice} />
                                ))}
                            </Carousel>
                        </Grid>
                    </motion.div>
                )}
            </AnimatePresence>
        </StickyBox>
    )
};

export default NoticeBar;