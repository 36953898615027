import React, { useState, useEffect } from 'react';
import {
    Box,
} from '@saladbob/sassafras';

import qs from 'qs';


import Blocks from '../components/blocks/Blocks';
import getSite from '../crud/getSite';
import getHome from '../crud/getHome';
import NoticeBar from '../components/news/NoticeBar';
import Layout from '../components/Layout';

import type { NextPageWithLayout, PageProps } from './_app';
import type { Page, Notice } from '../types';

type Props = {
    homepage: Page,
    notices: Notice[],
};

export async function getStaticProps() {
    const site = await getSite();
    const home = await getHome(process.env.NEXT_PUBLIC_API_URL);

    return {
        props: {
            homepage: home.homepage,
            ...site,
        },
    }
}

const Page: NextPageWithLayout<Props> = ({ homepage }) => {
    const [notices, setNotices] = useState<Notice[] | null>(null);
    useEffect(() => {
        const getNotices = async () => {
            const nqs = qs.stringify({
                where: {
                    and: [{
                        global: {
                            equals: true,
                        },
                        expiration: {
                            greater_than: new Date().toString(),
                        }
                    }],
                },
                depth: 1,
            });

            const noticeDocs = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/notice?${nqs}`).then(res => res.json());
            setNotices(noticeDocs.docs);
        };
        getNotices();
    }, []);
    
    return (
        <Box>
            <NoticeBar notices={notices} page="global" />
            <Blocks blocks={homepage.layout} />
        </Box>
    );
};

Page.getLayout = function getLayout(page: React.ReactElement, pageProps: PageProps ) {
    return (
        <Layout { ...pageProps }>
            {page}
        </Layout>
    )
}

export default Page;
