import React from 'react';
import { Notice } from '../../types';
import { Bell, Calendar, CloudDrizzle, XSquare } from 'react-feather';
import { GiBasketballBasket } from "react-icons/gi";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
    Box,
    Grid,
    Type,
}
from '@saladbob/sassafras'

import Link from 'next/link';

dayjs.extend(relativeTime);

const NoticeType: React.FC<{ type: string, notice: Notice }> = ({ type, notice }) => {
    switch (type) {
        case 'extension':
            return notice.tournaments && notice.tournaments.length > 0
                ? (<>
                    {notice.hosts && notice.hosts[0] && (
                        <Link href={`/local/${notice.hosts[0].slug}`}>
                            <Type font="p" uppercase weight="bold" color="secondary">
                                Now {dayjs(notice.tournaments[0].deadline).fromNow(true)} to register!
                            </Type>
                        </Link>
                    )}
                    {!notice.hosts && (
                        <Type font="p" uppercase weight="bold" color="secondary">
                            Now {dayjs(notice.tournaments[0].deadline).fromNow(true)} to register!
                        </Type>
                    )}
                </>)
                : null;
        case 'brackets':
            return (<>
                {notice.hosts && notice.hosts[0] && (
                    <Link href={`/local/${notice.hosts[0].slug}`}>
                        <Type font="p" uppercase weight="bold" color="secondary">
                            View the Brackets!
                        </Type>
                    </Link>
                )}
            </>);
        default:
            return notice.brief
                ? (<Type font="p">{notice.brief}</Type>)
                : null;
    }
};

const NoticeCard: React.FC<{ notice: Notice }> = ({ notice }) => {
    const icons = {
        extension: <Calendar size={32} />,
        weather: <CloudDrizzle size={32} />,
        cancelation: <XSquare size={32} />,
        date: <Calendar size={32} />,
        general: <Bell size={32} />,
        brackets: <GiBasketballBasket size={48} />,
    }

    return (<Box bgColor="tertiary">
        <Grid columns="min-content 1fr min-content" gridGap="md" padding="sm" align="center">
            {icons[notice.type]}
            <div>
                <Type tag="h3" font="h4">{notice.title}</Type>
                <Box>
                    <NoticeType type={notice.type} notice={notice} />
                </Box>
            </div>
            <Type font="control" nowrap>{dayjs(notice.createdAt).fromNow()}</Type>
        </Grid>
    </Box>);
}

export default NoticeCard;